// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-404-404-page-tsx": () => import("./../src/templates/404/404.page.tsx" /* webpackChunkName: "component---src-templates-404-404-page-tsx" */),
  "component---src-templates-about-us-about-us-page-tsx": () => import("./../src/templates/aboutUs/aboutUs.page.tsx" /* webpackChunkName: "component---src-templates-about-us-about-us-page-tsx" */),
  "component---src-templates-contact-contact-page-tsx": () => import("./../src/templates/contact/contact.page.tsx" /* webpackChunkName: "component---src-templates-contact-contact-page-tsx" */),
  "component---src-templates-homepage-homepage-page-tsx": () => import("./../src/templates/homepage/homepage.page.tsx" /* webpackChunkName: "component---src-templates-homepage-homepage-page-tsx" */),
  "component---src-templates-news-list-news-list-page-tsx": () => import("./../src/templates/newsList/newsList.page.tsx" /* webpackChunkName: "component---src-templates-news-list-news-list-page-tsx" */),
  "component---src-templates-products-products-page-tsx": () => import("./../src/templates/products/products.page.tsx" /* webpackChunkName: "component---src-templates-products-products-page-tsx" */),
  "component---src-templates-search-search-page-tsx": () => import("./../src/templates/search/search.page.tsx" /* webpackChunkName: "component---src-templates-search-search-page-tsx" */),
  "component---src-templates-single-news-single-news-page-tsx": () => import("./../src/templates/singleNews/singleNews.page.tsx" /* webpackChunkName: "component---src-templates-single-news-single-news-page-tsx" */),
  "component---src-templates-text-page-text-page-page-tsx": () => import("./../src/templates/textPage/textPage.page.tsx" /* webpackChunkName: "component---src-templates-text-page-text-page-page-tsx" */)
}

