import React from 'react';

import { PrimaryLayout } from '@lerna-monorepo/common/layouts/primary/primary.layout';
import { LayoutData } from './types';
import FaviconFile from './images/favicon.ico';

const Layout: React.FC<LayoutData> = data => {
  const { children, pageContext } = data;
  const {
    languages,
    menuObject,
    settings,
    availableTranslations,
    basePaths,
    metadata,
  } = pageContext;

  const defaultSeoData = settings.metadataSettings.find(item => {
    if (item.language.slug === pageContext.currentLanguage) {
      return item;
    }
  });

  const seoData = {
    title: metadata?.title ?? defaultSeoData.title,
    metaDescription: metadata?.description ?? defaultSeoData.description,
    favicon: FaviconFile,
  };

  const searchData = settings.searchSettings.filter(item => {
    if (item.language.slug === pageContext.currentLanguage) {
      return item;
    }
  });

  return (
    <PrimaryLayout
      availableTranslations={availableTranslations}
      seoData={seoData}
      languages={languages}
      menuData={menuObject}
      settings={settings}
      logoData={settings?.mainPageLogo}
      alertsSettings={settings.alertsSettings}
      adBannerDataKey="mainAlertSettings"
      returnToMainBannerDataKey="mainAlertSettings"
      searchSettings={searchData[0]}
      hasTextContentInAlertBar={false}
      basePaths={basePaths}
      pathname={data.location.pathname}
    >
      {children}
    </PrimaryLayout>
  );
};

export default Layout;
